import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import store from './store'
import VueCookies from 'vue-cookies'
import axios from "axios";
import VueAxios from "vue-axios";

const app = createApp(App)


app.use(router)
app.use(store)
app.use(VueCookies)
app.use(VueAxios, axios)
app.provide('axios', app.config.globalProperties.axios)
// app.axios.defaults.baseURL = `http://localhost:8000`;
app.axios.defaults.baseURL = `https://stageapi.shedever.studio`;
app.mount('#app')
